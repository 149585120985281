<template>
  <div>
    <b-container>
      <b-row class="d-flex align-items-center privacy-fa" v-if="this.$i18n.locale=='fa'">
        <b-col cols="12" md="12" class="text-center">
          <h2 class="my-4 text-center text-primary font-weight-bold">
            آشنایی با قابلیت های <span class="text-secondary">نرم افزار</span>
          </h2>
          <p class="w-50 mx-auto text-center">
            استفاده از خدمات مایندولوژی شامل سوال و جواب از متخصص روان شناس، تست
            های شخصیتی با تحلیل اختصاصی و ... کاملا رایگان می باشد. برای شروع
            اپلیکشن مایندولوژی را دانلود کنید.
          </p>
          <img src="@/assets/images/divider.png" alt="divider" class="my-4" />
          <div class="d-flex flex-column align-items-center mt-4">
            <b-button
              class="rounded-lg py-2 px-3 text-white"
              variant="secondary"
              @click="downloadApp()"
              >دانلود مستقیم نسخه اندروید نسخه 1.0.8</b-button
            >

            <b-button
              class="mt-4 p-0 w-auto"
              variant="white"
              @click="downloadFromGooglePlay()"
            >
              <v-img
                :src="require('@/assets/images/badge/google-play-badge-fa.png')"
                max-width="280"
              />
            </b-button>

            <b-button
              class="mt-4 p-0 w-auto"
              variant="white"
              @click="downloadFromBazzar()"
              >
                <v-img :src="require('@/assets/images/badge/cafebazaar-fa.png')" max-width="280"/>
              </b-button
            >

            <!-- <b-button
              class="mt-4 p-0 w-auto"
              variant="white"
              @click="downloadFromAnardooni()"
            >
              <v-img
                :src="
                  require('@/assets/images/badge/anardoni-badge-black-persian.png')
                "
                max-width="280"
              />
            </b-button> -->

            <b-button
              class="rounded-lg py-2 px-3 mt-4 text-white"
              variant="secondary"
              >دانلود از App Store (بزودی)</b-button
            >
          </div>
        </b-col>
      </b-row>

      <b-row class="d-flex align-items-center privacy-en" v-else>
        <b-col cols="12" md="12" class="text-center">
          <h2 class="my-4 text-center text-primary font-weight-bold">
            Familiarity with <span class="text-secondary">software</span> capabilities 
          </h2>
          <p class="w-50 mx-auto text-center">
            Use of mindology services including questions and answers from a psychologist, testing
             Personality with exclusive analysis and ... is completely free. To begin
             Download the Mindology app.
          </p>
          <img src="@/assets/images/divider.png" alt="divider" class="my-4" />
          <div class="d-flex flex-column align-items-center mt-4">
            <b-button
              class="rounded-lg py-2 px-3 text-white"
              variant="secondary"
              @click="downloadApp()"
              >Direct download Android v1.0.8 </b-button
            >

            <b-button
              class="mt-4 p-0 w-auto"
              variant="white"
              @click="downloadFromGooglePlay()"
            >
              <v-img
                :src="require('@/assets/images/badge/google-play-badge-en.png')"
                max-width="280"
              />
            </b-button>

            <b-button
              class="mt-4 p-0 w-auto"
              variant="white"
              @click="downloadFromBazzar()"
              >
                <v-img :src="require('@/assets/images/badge/cafebazaar-en.png')" max-width="280"/>
              </b-button
            >

            <!-- <b-button
              class="mt-4 p-0 w-auto"
              variant="white"
              @click="downloadFromAnardooni()"
            >
              <v-img
                :src="
                  require('@/assets/images/badge/anardoni-badge-black-persian.png')
                "
                max-width="280"
              />
            </b-button> -->

            <b-button
              class="rounded-lg py-2 px-3 mt-4 text-white"
              variant="secondary"
              >Download from App Store (soon)</b-button
            >
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-if="this.$i18n.locale=='fa'">
      <b-row>
        <b-col cols="12" md="12">
          <b-carousel
            id="carousel-download"
            style="text-shadow: 0px 0px 2px #000; border-radius:40px; overflow: hidden"
            indicators
            img-width="1024"
            img-height="480"
            controls
          >
            <b-carousel-slide
              :img-src="require('@/assets/images/download-slider-1.png')"
            ></b-carousel-slide>
            <b-carousel-slide
              :img-src="require('@/assets/images/download-slider-2.png')"
            ></b-carousel-slide>
            <b-carousel-slide
              :img-src="require('@/assets/images/download-slider-3.png')"
            ></b-carousel-slide>
          </b-carousel>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-if="this.$i18n.locale=='fa'">
      <b-row class="d-flex align-items-center">
        <b-col cols="12" md="12" class="text-center">
          <h2 class="my-4 text-center text-primary font-weight-bold">
            اگر سوال و ابهامی دارید می توانید <br />
            از <span class="text-secondary">تیم پشتیبانی ما</span> بپرسید
          </h2>
          <img src="@/assets/images/divider.png" alt="divider" class="my-4" />
        </b-col>

        <b-col cols="12" md="4" class="my-4">
          <v-card class="mx-auto curve-30" outlined>
            <v-list-item three-line>
              <v-list-item-content class="ms-3">
                <v-list-item-title class="mb-1 h5">
                  پست الکترونیکی
                </v-list-item-title>
                <v-list-item-subtitle>
                  hi@mindology.io
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-avatar
                size="80"
                color="#F0F7FF"
                class="position-absolute p-0 m-0"
                style="top:-40px; left:40px"
              >
                <img
                  src="@/assets/images/email.png"
                  alt="divider"
                  class="w-auto h-auto rounded-0"
                />
              </v-list-item-avatar>
            </v-list-item>
          </v-card>
        </b-col>

        <b-col cols="12" md="4" class="my-4">
          <v-card class="mx-auto curve-30" outlined>
            <v-list-item three-line>
              <v-list-item-content class="ms-3">
                <v-list-item-title class="mb-1 h5">
                  تماس تلفنی
                </v-list-item-title>
                <v-list-item-subtitle>
                  09122945346
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-avatar
                size="80"
                color="#FFF0E9"
                class="position-absolute p-0 m-0"
                style="top:-40px; left:40px"
              >
                <img
                  src="@/assets/images/phone.png"
                  alt="divider"
                  class="w-auto h-auto rounded-0"
                />
              </v-list-item-avatar>
            </v-list-item>
          </v-card>
        </b-col>

        <b-col cols="12" md="4" class="my-4">
          <v-card class="mx-auto curve-30" outlined>
            <v-list-item three-line>
              <v-list-item-content class="ms-3">
                <v-list-item-title class="mb-1 h5">
                  ربات پشتیبانی
                </v-list-item-title>
                <v-list-item-subtitle>
                  از <b-link class="text-secondary">اینجا</b-link> استفاده کنید
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-avatar
                size="80"
                color="#ECFFF4"
                class="position-absolute p-0 m-0"
                style="top:-40px; left:40px"
              >
                <img
                  src="@/assets/images/bot.png"
                  alt="divider"
                  class="w-auto h-auto rounded-0"
                />
              </v-list-item-avatar>
            </v-list-item>
          </v-card>
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      v-model="modalShow"
      size="sm"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
    >
      <template>
        <img
          src="@/assets/images/comming-soon.gif"
          alt="comming-soon"
          class="w-100 m-0 p-0"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalShow: false,
      langIsFa: true,
    };
  },
  methods: {
    downloadApp() {
      window.open("https://media.servicemind.ir/app/android/mindology-1.0.6.apk");
    },
    downloadFromGooglePlay() {
      window.open(
        "https://play.google.com/store/apps/details?id=com.mindology.app"
      );
    },
    downloadFromBazzar() {
      window.open("https://cafebazaar.ir/app/com.mindology.app");
    },
    downloadFromAnardooni() {
      window.open("https://anardoni.com/ios/app/3QFzGyNfU");
    },
    getImage(src) {
      return require(`@/assets/images/${src}.png`);
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
